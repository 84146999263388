@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  overflow: hidden;
}

.home-container {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 40px;
  padding: 15px;
  box-shadow: 
    0 0 0 8px rgba(255, 255, 255, 0.5), 
    0 0 0 9px rgba(200, 200, 200, 0.5),
    0 15px 40px rgba(0, 0, 0, 0.1);
  width: 90vw;
  height: 80vh;
  max-width: 420px;
  max-height: 700px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1; /* 确保手机容器在粒子背景之上 */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  overflow: hidden;
  padding: 12px 10px;
  box-sizing: border-box;
}

/* 确保所有内容都不会超出容器宽度 */
.header-content, .content-middle, nav, .nav-links {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.nav-links li {
  width: 75%; /* 减少宽度，原来是 80% */
  max-width: 220px; /* 减少最大宽度，原来是 240px */
}

.nav-links a {
  text-decoration: none;
  color: #7f9cf5;
  font-weight: 500;
  font-size: 0.85rem;
  padding: 0.6rem 0.9rem; /* 增加垂直内边距，减少水平内边距。原来是 0.5rem 1rem */
  border-radius: 8px;
  width: 100%;
  text-align: center;
  display: block;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(127, 156, 245, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  word-wrap: break-word;
}

.nav-links a::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #7f9cf5, #a3bffa, #7f9cf5);
  z-index: -1;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.nav-links a:hover {
  color: #fff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.nav-links a:hover::before {
  opacity: 1;
}

.nav-links a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: -1;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-links li:nth-child(1) { animation-delay: 0.2s; }
.nav-links li:nth-child(2) { animation-delay: 0.4s; }
.nav-links li:nth-child(3) { animation-delay: 0.6s; }
.nav-links li:nth-child(4) { animation-delay: 0.8s; }
.nav-links li:nth-child(5) { animation-delay: 1s; }

.header-content {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.3rem;
}

nav {
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.profile-image-container {
  width: 110px; /* 增加头像尺寸 */
  height: 110px;
  margin: 0.2rem auto; /* 保持原有的边距 */
  position: relative;
  transition: all 0.3s ease;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.profile-image-container::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.profile-image-container:hover::after {
  opacity: 1;
}

.profile-image-container:hover .profile-image {
  transform: scale(0.95);
  border-color: transparent;
}

.content-middle {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.2rem 0;
}

/* 适应不同的屏幕尺寸 */
@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .home-container {
    border-radius: 35px;
    height: 85vh;
  }
  
  .content-wrapper {
    padding: 8px;
  }
  
  .nav-links li {
    width: 85%;
  }
}

@media (min-aspect-ratio: 1/1) {
  .home-container {
    width: 40vh;
    height: 80vh;
    max-width: 380px;
    max-height: 700px;
  }
  
  .content-wrapper {
    padding: 15px 12px;
  }
  
  .header-content {
    margin-top: 5px;
    margin-bottom: 0.5rem;
  }
  
  .content-middle {
    margin: 0.4rem 0;
  }
  
  nav {
    margin-top: 0.5rem;
    margin-bottom: 5px;
  }
  
  .nav-links {
    gap: 0.4rem;
  }
}

/* 针对更大的屏幕进行额外调整 */
@media (min-width: 768px) and (min-height: 1024px) {
  .home-container {
    height: 75vh;
    max-height: 650px;
  }
  
  .content-wrapper {
    padding: 18px 15px;
  }
  
  .header-content {
    margin-top: 8px;
    margin-bottom: 0.7rem;
  }
  
  .content-middle {
    margin: 0.6rem 0;
  }
  
  .profile-image-container {
    width: 120px; /* 在大屏幕上稍微增加尺寸 */
    height: 120px;
  }
  
  nav {
    margin-top: 0.7rem;
    margin-bottom: 8px;
  }
  
  .nav-links {
    gap: 0.5rem;
  }
  
  .nav-links li {
    width: 70%; /* 在大屏幕上进一步减少宽度 */
    max-width: 200px; /* 减少最大宽度 */
  }
  
  .nav-links a {
    padding: 0.7rem 1rem; /* 稍微增加内边距 */
    font-size: 0.95rem; /* 保持字体大小不变 */
  }
}

/* 针对非常大的屏幕 */
@media (min-width: 1200px) and (min-height: 1200px) {
  .home-container {
    height: 70vh;
    max-height: 800px;
  }
  
  .content-wrapper {
    padding: 20px 18px;
  }
  
  .content-middle {
    margin: 0.4rem 0;
  }
  
  .profile-image-container {
    width: 130px; /* 在更大的屏幕上进一步增加尺寸 */
    height: 130px;
  }
  
  .nav-links li {
    width: 65%; /* 在更大的屏幕上进一步减少宽度 */
    max-width: 190px; /* 减少最大宽度 */
  }
  
  .nav-links a {
    padding: 0.8rem 1.1rem; /* 进一步增加内边距 */
    font-size: 1rem; /* 保持字体大小不变 */
  }
}

.notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  z-index: 10;
}

.name {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #3498db, #8e44ad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 2s infinite linear;
  line-height: 1.2;
}

@keyframes shimmer {
  0% { background-position: -200px 0; }
  100% { background-position: 200px 0; }
}

.title {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0.1rem;
  color: #34495e;
  line-height: 1.1;
}

.subtitle {
  font-size: 0.85rem;
  font-weight: 300;
  color: #7f8c8d;
  line-height: 1.1;
}

